import { helpers } from '@vuelidate/validators';

const passwordValidator = helpers.regex(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*#?&]{12,24}$/,
);

const imageValidator = (file) => {
  if (file == null) return true;

  const maxSize = 5 * 1024 * 1024; // 5MB
  const allowedTypes = ['image/jpeg', 'image/png'];

  if (!allowedTypes.includes(file.type) || file.size > maxSize) {
    return false;
  }

  return true;
};

function isInt(value) {
  return (
    !isNaN(value) &&
    (function (x) {
      return (x | 0) === x;
    })(parseFloat(value))
  );
}

function isStatusActiveRequired(value, currentSector) {
  if (currentSector.status) return value?.length ? true : false;
  return true;
}

function isStatusActiveIntegerRequired(value, currentSector) {
  if (currentSector.status) return isInt(value);
  else return !value?.length ? true : isInt(value);
}

function timeDifference(value, currentSector) {
  const [startHour, startMinute] = currentSector.timeStart.split(':').map(Number);
  const [endHour, endMinute] = currentSector.timeEnd.split(':').map(Number);

  if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) return false;
  else return true;
}

function requiredIfAtLeastOnePermissionSelected(value) {
  return value.some((module) => module.permissions.length > 0);
}

export {
  passwordValidator,
  imageValidator,
  isStatusActiveRequired,
  isStatusActiveIntegerRequired,
  timeDifference,
  requiredIfAtLeastOnePermissionSelected,
};
