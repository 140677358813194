<template>
  <section class="profile mb-4">
    <h2 class="profile__welcome mb-4">Привет, {{ userData.name }}</h2>

    <form @submit.prevent="onSubmitForm" class="d-flex align-items-center">
      <div class="d-flex flex-column me-4">
        <label class="profile__avatar-wrapper" for="profile__avatar-input">
          <img class="profile__avatar" :src="state.avatarUrl" alt="avatar" />
          <div v-if="state.isAvatarLoading" class="profile__avatar-loading">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Загрузка...</span>
            </div>
          </div>
        </label>

        <label for="profile__avatar-input" class="profile__change-avatar-btn"
          >Изменить фото</label
        >

        <input
          @change="onAvatarChange"
          id="profile__avatar-input"
          class="hidden"
          type="file"
          accept="image/png, image/jpeg"
        />
      </div>

      <div class="profile__form">
        <div class="profile__form-item-wrapper">
          <input
            class="profile__form-item"
            type="text"
            :placeholder="userData.name"
            disabled
          />
          <div class="profile__item-svg-wrapper">
            <inline-svg
              class="disabled"
              :src="require('@/assets/images/lock.svg')"
              fill="#717c9933"
            />
          </div>
        </div>

        <div class="profile__form-item-wrapper">
          <input
            v-model.trim="state.email"
            class="profile__form-item"
            :class="{ error: v$.email.$error }"
            type="text"
            placeholder="Почта"
          />

          <span class="error-warning" v-if="v$.email.$error"
            >Некорректный e-mail</span
          >
        </div>

        <div class="d-block">
          <div class="profile__form-item-wrapper">
            <template v-if="!isShowPassword">
              <input
                v-model.trim="state.password"
                class="profile__form-item"
                :class="{ error: v$.password.$error }"
                type="password"
                placeholder="Пароль"
              />

              <button
                type="button"
                @click="isShowPassword = true"
                class="profile__item-svg-wrapper"
              >
                <inline-svg
                  :src="require('@/assets/images/show.svg')"
                  fill="#717C99"
                />
              </button>
            </template>

            <template v-else>
              <input
                v-model.trim="state.password"
                class="profile__form-item"
                :class="{ error: v$.password.$error }"
                type="text"
                placeholder="Пароль"
              />

              <button
                type="button"
                @click="isShowPassword = false"
                class="profile__item-svg-wrapper"
              >
                <inline-svg
                  :src="require('@/assets/images/hide.svg')"
                  fill="#717C99"
                  opacity="0.2"
                />
              </button>
            </template>
          </div>
          <span class="error-warning" v-if="v$.password.$error">
            Пароль должен быть сложным, то есть состоять минимум из 12 символов:
            больших и маленьких букв, цифр и специальных знаков
          </span>
        </div>

        <div class="profile__form-btns d-flex align-items-center">
          <button
            @click.prevent="onResetForm"
            type="reset"
            class="profile__form-cancel-btn"
            :disabled="state.isFormLoading"
          >
            Отменить
          </button>
          <button
            type="submit"
            class="profile__form-save-btn"
            :disabled="state.isFormLoading"
          >
            <span>Сохранить</span>
            <div
              v-if="state.isFormLoading"
              class="spinner-border spinner-border-sm ms-2"
              role="status"
            >
              <span class="visually-hidden">Загрузка...</span>
            </div>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";

import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { passwordValidator, imageValidator } from "@/helpers/validators";
import { successSaveAlert, errorAlert } from "@/helpers/alerts";

export default {
  name: "profile-page",
  setup() {
    const store = useStore();
    const userData = computed(() => store.getters.user);
    const isShowPassword = ref(false);
    const state = reactive({
      id: userData.value.id,
      avatar: null,
      avatarUrl: require("@/assets/images/avatar.jpg"),
      isAvatarLoading: false,
      email: userData.value.email,
      password: "",
      isFormLoading: false,
    });
    const rules = {
      email: { required, email },
      password: { required, passwordValidator },
    };
    const v$ = useVuelidate(rules, state);

    function onResetForm() {
      Object.assign(state, {
        id: userData.value.id,
        email: userData.value.email,
        password: "",
      });
      v$.value.$reset();
    }

    async function onSubmitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      state.isFormLoading = true;

      await store.dispatch("profileUpdate", {
        id: state.id,
        email: state.email,
        password: state.password,
      });

      state.isFormLoading = false;
      onResetForm();
    }

    function onAvatarChange(e) {
      state.isAvatarLoading = true;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const file = event.target.files[0];

      if (!imageValidator(file)) {
        errorAlert(
          `Аватарка должна быть в формате <b class="text-nowrap">PDF/JPG</b> и иметь размер не более 5МБ!`
        );

        state.isAvatarLoading = false;
        return;
      }

      setTimeout(() => {
        state.avatar = file;
        state.avatarUrl = URL.createObjectURL(file);
        state.isAvatarLoading = false;

        successSaveAlert();
      }, 1500);
    }

    return {
      isShowPassword,
      state,
      userData,
      v$,
      onSubmitForm,
      onResetForm,
      onAvatarChange,
    };
  },
};
</script>