<template>
  <router-view name="topbar" />
  <UserProfile />
  <router-view />
</template>

<script>
import UserProfile from "@/components/profile/UserProfile.vue";

export default {
  name: "profile-page",
  components: {
    UserProfile,
  },
};
</script>